/* .syncHistory-modal {
    
} */

.sync-history-model .ant-modal-body {
    max-height: 600px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.sync-history-model .ant-timeline .ant-timeline-item {
    margin-left: -380px !important;
}

.sync-history-model .ant-modal-title {
    margin-bottom: 20px !important;
}
.syncHistoryItem {
    margin-top: 10px !important;
    padding-right: 20px !important;
}
