.modal_footer {
    display: flex;
    gap: 10px;
    justify-content: center;
}
.image_shopify {
    max-width: 36rem !important;
    height: 3rem !important;
    width: 12rem !important;
    margin-bottom: 10px;
}
.form-label {
    margin-bottom: 10px;
    font-weight: bold;
}
