.login-container {
    width: 500px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 50px;
    padding: 20px;
}

.login-img{ 
    text-align: center;
}