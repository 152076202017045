.container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
}

.mainContainer {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    padding-top: 0;
    padding-bottom: 0;
}
