.container {
    padding: 0px 10px;
}

.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card {
    width: 49.5%;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid #e5e5e5; */
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.cardBody {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    height: 18rem;
}
.chartContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    /* border: 1px solid #e5e5e5; */
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.modal-chart {
    height: 100px;
}

.pie-chart-modal .ant-modal-body {
    height: 35rem !important;
    overflow-y: auto !important;
}

.centerData {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
