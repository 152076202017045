@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
::after,
::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Lexend', sans-serif !important;
}

:root {
    --main-color: #0074ff;
}

body {
    font-family: 'Lexend', sans-serif;
}

.ml-15 {
    margin-left: 15px !important;
}
.mr-1 {
    margin-right: 10px;
}
.ml-20 {
    margin-left: 20px !important;
}
.fs-20 {
    font-size: 20px;
}
.cl-red {
    color: red;
}
.cl-green {
    color: green;
}
.delete-icon {
    text-align: center;
    font-size: 25px;
    color: #6c8289;
}
.delete-text {
    font-size: 18px;
    padding: 10px;
    text-align: center;
}
.active-route {
    border-bottom: 3px solid #6c8289;
}

.mt-20 {
    margin-top: 20px;
}

.justify-content-end {
    justify-content: end;
}
.d-flex {
    display: flex;
}
.image_hubspot {
    max-width: 36rem !important;
    height: 3rem !important;
    width: 10rem !important;
}
/* 
.center {
    display: flex;
    justify-content: center;
} */

.disabled-btn {
    background-color: #d9d9d9 !important;
    color: #6c8289 !important;
}

::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 12px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    /* background: rgb(52, 71, 53); */
    background: #6c8289 !important;
    border-radius: 12px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6c8289 !important;
    border-radius: 12px !important;
}

.ant-table-wrapper .ant-table {
    scrollbar-color: unset !important;
}

.required-color {
    color: red;
}
