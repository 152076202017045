.user-name-box {
    height: 2.5rem;
    width: 2.5rem;
    background: #b7c1c5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.name {
    font-weight: 500;
    text-transform: uppercase;
}

.profile-menu {
    display: flex;
    align-items: center;
    gap: 10px;
}
