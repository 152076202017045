.profile-modal__header {
    padding: 1.1rem 3rem !important;
    align-items: center;
    width: 100%;
    min-height: 6rem !important;
    justify-content: space-between;
}

.profile-modal__body {
    padding: 1rem 3rem !important;
    color: #27272e !important;
    font-weight: 500;

    .fields {
        padding: 1rem !important;
    }

    .ant-select {
        width: 100% !important;
    }

    .ant-select-selector {
        line-height: normal;
    }
}

hr {
    height: 1px;
    background-color: #ccc;
    border: none;
}

.ant-modal-close {
    margin-top: 2rem;
    margin-right: 2.1rem;
}

.userDetailsTitle {
    font-size: 2rem !important;
}

.profile-form-label {
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
}

.image_hubspot {
    max-width: 36rem !important;
    height: 6rem !important;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.center {
    display: flex;
    justify-content: center;
}

.apiKey_popup {
    .ant-modal-content {
        padding: 0 !important;
    }
}

.modal_footer {
    display: flex;
    gap: 10px;
    justify-content: center;
}
