.mr-5 {
    margin-top: 5%;
}
.company-text {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1rem;
}

.integration-card {
    align-items: center;
    margin: 10px 0;
    height: 15rem;
}

.integration-card-header {
    padding: 12px 0px !important;
}
