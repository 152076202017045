.profile-modal__header {
    padding: 1.1rem 3rem !important;
    align-items: center;
    width: 100%;
    min-height: 6rem !important;
    justify-content: space-between;
}

.profile-modal__body {
    padding: 1rem 3rem !important;
    color: #27272e !important;
    font-weight: 500;
}

.profile-form-label {
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
}

.image_woocommerce {
    max-width: 220px !important;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.center {
    display: flex;
    justify-content: center;
}

.apiKey_popup .ant-modal-content {
    padding: 0 !important;
}

.modal_footer {
    display: flex;
    gap: 10px;
    justify-content: center;
}
