.sync-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.arrow {
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    position: relative;
    font-family: Arial, sans-serif;
    text-align: center;
    margin-right: 25px;
    width: 100px;
}

.arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -19px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
}

.synced {
    background: green;
}

.synced::after {
    border-left: 20px solid green;
}

.unsynced {
    background: grey;
}

.unsynced::after {
    border-left: 20px solid grey;
}

.pending {
    background: orange;
}

.pending::after {
    border-left: 20px solid orange;
}

.syncHistory {
    display: flex;
    align-items: center;
    color: #1677ff;
    cursor: pointer;
    gap: 8px;
}

.notSyncHistory {
    display: flex;
    align-items: center;
    color: #1677ff;
    cursor: not-allowed;
    pointer-events: none;
    gap: 8px;
}

.modal-content {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 1rem 0px;
    color: grey;
}

.filer-content {
    display: flex;
    gap: 20px;
    width: 25rem;
}

.content-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}
