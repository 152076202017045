.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 85px);
}
.CloseCircle {
    font-size: 50px;
    color: red;
}
