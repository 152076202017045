.header {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #dbe1e3;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-menus {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.header-menu {
    color: black !important;
    font-size: 18px;
    height: 20px;
    text-decoration: none;
}

.main-content {
    padding: 10px 40px;
    height: calc(100vh - 61px);
    overflow: auto;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 5px;
    /* margin-right: 5.2rem; */
}

.user-name {
    font-size: 15px;
}
